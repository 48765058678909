import { IPrebidAdUnit, IBidResponse } from '../../typings/IPrebid'
import { deepcopy } from '../helpers/deepcopy'
import { Device } from '../helpers/Device'
import type { Slot } from '../slots/Slot'

/**
 * ### In-Banner Video
 *
 * This is a video ad that displays within the space of a normal
 * banner slot. Technically an outstream ad, these differ from
 * our hasOutstream ads because the partner provides the renderer.
 * Where outstream provides a VAST tag and expects us to render a
 * video, these provide a VAST tag AND render it all in one.
 *
 * Because of this distinction, we can treat these a lot more like
 * a normal banner ad. In most ways, we don't have to care that its
 * displaying a video instead of a banner ad. The only things we
 * need to concern ourselves with is
 * 1. They get separate placements for the sake of reporting, flooring
 * and discrepancies and
 * 2. Their density is variable to prevent bogging down the page with
 * too many video ads.
 */
export function hasIBV(target: Constructor<Slot>): any {
  return class extends target {
    validate(): boolean {
      return super.validate()
    }

    async adunits(): Promise<IPrebidAdUnit[]> {
      const adunits: Array<IPrebidAdUnit> = []

      if (this.ibvConfig?.meetsIBVCriteria()) {
        const bannerOutstreamAdunit = this.getIBVAdunit()
        if (bannerOutstreamAdunit) {
          adunits.push(bannerOutstreamAdunit)
        }
      }

      adunits.push(...(await super.adunits()))

      return adunits
    }

    /**
     * Get bids requests for Banner Outstream Adunits.
     * i.e.: Triplelift and Yieldmo, who use their own outstream players, not ours.
     */
    private getIBVAdunit(): IPrebidAdUnit | undefined {
      let bidRequestKey
      let adunitName: string
      if (Device.isMobileOrTablet) {
        bidRequestKey = `${this.slotName}_ibv_mobile`
        adunitName = `${this.id}_ibv_mobile`
      } else {
        bidRequestKey = `${this.slotName}_ibv_desktop`
        adunitName = `${this.id}_ibv_desktop`
      }

      const bidRequests = this.model.bidRequests[bidRequestKey]
      if (!bidRequests) {
        return undefined
      }
      const outstreamBidRequests = deepcopy(
        bidRequests,
        'Error copying outstream bid requests'
      )

      if (!outstreamBidRequests) {
        return undefined
      }

      outstreamBidRequests.forEach((bid) => {
        bid.isOutstream = true
        bid.outstreamAdUnitCode = adunitName
      })

      return {
        code: adunitName,
        bids: outstreamBidRequests,
        mediaTypes: {
          banner: {
            sizes: this.ibvConfig?.ibvSizes() || []
          }
        }
      }
    }
  }
}
