export enum Mime {
  '3gpp' = 'video/3gpp',
  '3gpp2' = 'video/3gpp2',
  flash = 'application/x-shockwave-flash',
  flv = 'video/x-flv',
  javascript = 'application/javascript',
  m4v = 'video/x-m4v',
  mp4 = 'video/mp4',
  mpeg = 'video/mpeg',
  ogg = 'video/ogg',
  quicktime = 'video/quicktime',
  webm = 'video/webm',
  wmv = 'video/x-ms-wmv'
}

export enum Stream {
  dash = 'application/dash+xml',
  hls = 'application/x-mpegURL'
}

export enum Codec {
  h264 = 'video/mp4;codecs="avc1"',
  h265 = 'video/mp4;codecs="hvc1"',
  h265Mobile = 'video/mp4;codecs="hec1"',
  vp9 = 'video/webm;codecs="vp9"'
}

export type ContentType = Mime | Stream | Codec

export default ContentType
