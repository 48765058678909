import { IPrebidAdUnit, IBidResponse } from '../../typings/IPrebid'
import { Device } from '../helpers/Device'
import type { Slot } from '../slots/Slot'
import { IVideoAdTagOptions } from '../../typings/IVideoOptions'
import { LiftTest } from '../liftTest/LiftTest'
import { Mime } from '../enums/ContentType'
import getContentWidth from '../helpers/DOM/getContentWidth'
import type { AuctionHouse } from '../auctionHouse/AuctionHouse'
import type { OutstreamRenderer } from './OutstreamRenderer'
import { getQueryParam } from '../helpers/getQueryParam'

// eslint-disable-next-line no-magic-numbers
const videoRatio = 9 / 16

const loglevel = getQueryParam('loglevel', window.location.search)
export const IMA_CDN =
  loglevel === 'debug'
    ? 'https://imasdk.googleapis.com/js/sdkloader/ima3_debug.js'
    : 'https://imasdk.googleapis.com/js/sdkloader/ima3.js'

/** Adds outstream bid requests to slot */
export function hasOutstream(target: Constructor<Slot>): any {
  return class extends target {
    outstreamRenderer: OutstreamRenderer

    validate(): boolean {
      return super.validate()
    }

    cleanup() {
      super.cleanup()
      if (this?.outstreamRenderer?.container) {
        this.outstreamRenderer.container.remove()
        this.outstreamRenderer.cleanup()
      }
    }

    async adunits(): Promise<IPrebidAdUnit[]> {
      const adunits: Array<IPrebidAdUnit> = []

      if (this.meetsOutstreamCriteria()) {
        const outstreamAdunit = await this.getOutstreamAdunit()
        if (outstreamAdunit) {
          adunits.push(outstreamAdunit)
        }
      }

      adunits.push(...(await super.adunits()))

      return adunits
    }

    private async getOutstreamAdunit(): Promise<IPrebidAdUnit | undefined> {
      let adunitName: string
      const { default: VideoPrebidManager } = await import(
        /* webpackChunkName: "afterScroll"*/ '../prebidFacade/videoBids/VideoPrebidManager'
      )
      const { OutstreamRenderer } = await import(
        /* webpackChunkName: "afterScroll"*/ './OutstreamRenderer'
      )

      if (Device.isMobileOrTablet) {
        adunitName = `${this.id}_outstream_mobile`
      } else {
        adunitName = `${this.id}_outstream_desktop`
      }

      const videoOptions = this.getVideoOptions(adunitName)
      const videoPrebidManager = new VideoPrebidManager(
        videoOptions as IVideoAdTagOptions,
        window.$mediavine.web.model,
        undefined,
        {} as AuctionHouse, // This should be safe for Outstream Bids
        {} as LiftTest
      )
      const outstreamAdunits = videoPrebidManager.getAdUnits()
      const liftTest = LiftTest.new(this.model)

      outstreamAdunits.bids = outstreamAdunits.bids.filter((bid) => {
        if (
          liftTest.bidder === bid.bidder &&
          liftTest.isPrebid() &&
          liftTest.isOutstream()
        ) {
          return false
        }

        bid.isOutstream = true
        bid.outstreamAdUnitCode = adunitName

        return true
      })

      // Set up the renderer for our outstream-video bids

      outstreamAdunits.renderer = {
        url: IMA_CDN,
        render: (async (bid: IBidResponse) => {
          this.outstreamRenderer =
            this.outstreamRenderer || OutstreamRenderer.new(this)
          await this.outstreamRenderer.loadAd(bid)
        }).bind(this)
      }

      return outstreamAdunits
    }

    /**
     * Constructs the Outstream VideoOptions needed for VideoPrebidManager
     * @param adunitName
     */
    private getVideoOptions(adunitName: string): Partial<IVideoAdTagOptions> {
      const [contentWidth, contentHeight] = getOutstreamPlayerSize(
        this,
        this.target?.parentElement
      )

      const videoOptions: Partial<IVideoAdTagOptions> = {
        prebid: true,
        autoplay: true,
        placement: this.outstreamPlacement,
        mimes: [Mime.javascript, Mime.mp4],
        contentHeight: contentHeight,
        contentWidth: contentWidth,
        adunitId: adunitName,
        mobile: Device.isMobileOrTablet,
        isOutstream: true // This is how VideoPrebidManager knows this is an outstream request
      }

      return videoOptions
    }
  }
}

export function getOutstreamPlayerSize(
  slot: Slot,
  parentElement?: HTMLElement | null
): AdSize {
  const maxWidth = slot.maxOutstreamWidth
  const contentWidth = Math.min(
    maxWidth,
    (parentElement && getContentWidth(parentElement)) || maxWidth
  )
  const contentHeight = Math.round(contentWidth * videoRatio)

  return [Math.round(contentWidth), Math.round(contentHeight)]
}
