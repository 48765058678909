import EventManager from '../../eventManager/EventManager'
import { Device } from '../../helpers'
import { writeCss } from '../../helpers/DOM'
import { overrideAncestors } from '../../helpers/DOM/overrideAncestors'
import { doOnce } from '../../helpers/doOnce'
import { queryParamHas } from '../../helpers/getQueryParam'
import type WebModel from '../../models/WebModel'
import type { Slot } from '../Slot'
import { adBoxIsEnabled } from './AdBox'
import { railSlideSelector, railStickySelector } from './RailStructure'

const inViewFrameHeight = 400

export const inviewStickyClass = 'mv-inview-sticky'

export interface IInviewSlot {
  inViewIsEnabled: boolean
  applyInViewLayout: () => void
  removeInViewLayout: () => void
}

export const hasInView = (target: Constructor<Slot>): any =>
  class extends target implements IInviewSlot {
    constructor(...args) {
      super(...args)

      if (!this.inViewIsEnabled) {
        return
      }

      this.setupSlotAndModel()
    }

    private setupSlotAndModel() {
      // setup slot config with rail properties
      this.onRail = true
      this.restrictRefreshSize = false
      // when used with adbox, configure it to fit the rail
      if (this.adBoxConfig) {
        this.adBoxConfig.heightPxToContain = inViewFrameHeight
      }

      // after wrapper has rendered, wrap the slot in DOM that makes it rail
      this.rail.setupWrapElementListener()
      EventManager.on(EventManager.events.slotWrapperRenderEnded, (slot) => {
        if (slot !== this) {
          return
        }
        insertStyles(getStyles())
        overrideAncestors(
          this.rail.sticky,
          { overflow: 'visible' },
          queryParamHas('test', 'mobileInview')
        )
      })
    }

    get inViewIsEnabled(): boolean {
      return inViewIsEnabled(this.model)
    }

    applyInViewLayout() {
      this.rail.slide.classList.add(inviewStickyClass)
      this.rail.sticky.classList.add(inviewStickyClass)
      const adReportSpace = this.rail.spaceNeededForAdReport()
      this.rail.slide.style.paddingBottom = `${adReportSpace}px`
    }

    removeInViewLayout() {
      this.rail.slide.classList.remove(inviewStickyClass)
      this.rail.sticky.classList.remove(inviewStickyClass)
      this.rail.slide.style.paddingBottom = ''
    }
  }

export function inViewIsEnabled(
  model: Pick<WebModel, 'mobile_inview' | 'desktop_inview' | 'ad_box'>
): boolean {
  return (
    adBoxIsEnabled(model) &&
    ((Device.isMobileOrTablet && model.mobile_inview) ||
      (Device.isDesktop && model.desktop_inview))
  )
}

const insertStyles = doOnce((styles: string) => writeCss(styles))

function getStyles() {
  return `
  .${railSlideSelector}.${inviewStickyClass} {
    display: block;
  }

.${railStickySelector}.${inviewStickyClass} {
    position: sticky;
    position: -webkit-sticky;
  }
`
}
