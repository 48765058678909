import type { Slot } from '../slots/Slot'
import { deepcopy } from '../helpers/deepcopy'
import { IPrebidAdUnit } from '../../typings/IPrebid'
import type WebModel from '../models/WebModel'
import { queryParamHas } from '../helpers/getQueryParam'

export const nativeWrapperSelector = 'mv-native-ad'

/**
 * Decorator class
 * When added to a Slot child class:
 * Adds native bid requests to the adunits method.
 */
export const hasNative = (target: Constructor<Slot>): any =>
  class extends target {
    nativeTemplate: string
    constructor(model: ISlotModel, webModel: WebModel) {
      super(model, webModel)
    }

    async adunits(): Promise<IPrebidAdUnit[]> {
      const adunits = await super.adunits()

      if (nativeIsDisabled(this.model)) {
        return adunits
      }

      const [
        { buildRequestFromTemplate },
        { getTemplate }
      ] = await Promise.all([
        import(
          /* webpackChunkName: "afterScroll"*/ './helpers/buildRequestFromTemplate'
        ),
        import(/* webpackChunkName: "afterScroll"*/ './helpers/getTemplate')
      ])

      const nativeTemplate = getTemplate(this)
      const bids = deepcopy(this.model.bidRequests['native'])
      bids.map((b) => (b.isNative = true))
      const native = buildRequestFromTemplate(nativeTemplate)

      return [
        ...adunits,
        {
          code: this.id,
          bids,
          mediaTypes: {
            native
          }
        }
      ]
    }
  }

function nativeIsDisabled(model: WebModel) {
  return (
    !model.mv_native_enabled &&
    !(
      queryParamHas('test', 'nativeTestAd') ||
      queryParamHas('test', 'nativeTestVideoAd')
    )
  )
}
