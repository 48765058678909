import EventManager from '../../eventManager/EventManager'
import { Device, WindowSize } from '../../helpers'
import { writeCss } from '../../helpers/DOM'
import { overrideAncestors } from '../../helpers/DOM/overrideAncestors'
import { doOnce } from '../../helpers/doOnce'
import { getQueryParam, queryParamHas } from '../../helpers/getQueryParam'
import { getIntersectionObserver } from '../../helpers/Observer'
import WebModel from '../../models/WebModel'
import { Slot } from '../Slot'
import SlotRepository from '../SlotRepository'
import { adBoxIsEnabled } from './AdBox'
import { railFrameSelector } from './RailStructure'

export const interscrollerOuterClass = 'mv-interscroller-outer'
export const interscrollerInnerClass = 'mv-interscroller-inner'
export const interscrollerMinHeight = 401

const adReportSpace = 22
const maxAdHeight = 600
const minPercentForViewable = 0.51
const minHeightToBeViewable =
  maxAdHeight * minPercentForViewable + adReportSpace + 1

const interscrollerObserverConfig = {
  threshold: 0,
  rootMargin: '200px 0px 200px 0px'
}

// various query param controls to change behavior
const forceInterscroll = queryParamHas('forceInterscroll', 'true')
const forceTallOnly = queryParamHas('forceTallOnly', 'true')
let queryParamShift = getQueryParam('parallaxShift') || ''
if (queryParamShift && typeof queryParamShift === 'object') {
  queryParamShift = queryParamShift[0]
}
const queryParamShiftInt = parseInt(queryParamShift, 10) || 0
const queryParamFast = queryParamHas('parallaxFast', 'true')
const queryParamDisable = queryParamHas('parallaxDisable', 'true')

export interface IInterscrollerSlot {
  isInWindow: boolean
  interscrollerEnalbedForSlot: boolean
  interscrollerLayoutApplies: boolean
  applyInterscrollerLayout: () => void
  removeInterscrollerLayout: () => void
  updatePosition: () => void
}

export const hasInterscroller = (target: Constructor<Slot>): any =>
  class extends target implements IInterscrollerSlot {
    isInWindow: boolean = false

    constructor(...args) {
      super(...args)

      if (!this.interscrollerIsEnabled) {
        return
      }

      this.interscrollerSetup()
    }

    private interscrollerSetup(): void {
      insertStyles(getStyles())
      this.setupObserver(this)
      if (this.adBoxConfig) {
        this.adBoxConfig.heightPxToContain = minHeightToBeViewable
      }
      this.rail.setupWrapElementListener()
      if (useFixedPosition()) {
        EventManager.on(EventManager.events.slotWrapperRenderEnded, (slot) => {
          if (slot !== this) {
            return
          }
          overrideAncestors(
            this.wrapper,
            { '-webkit-transform': 'none', transform: 'none' },
            forceInterscroll
          )
        })
      }
    }

    validate() {
      return (
        super.validate() &&
        /** Ensure a slot gets a valid interscroller config if applied */
        hasValidInterscrollerConfig(this)
      )
    }

    get sizes(): AdSizes {
      const currentSizes = super.sizes
      if (!this.interscrollerIsEnabled) {
        return currentSizes
      }

      if (!this.interscrollerEnalbedForSlot) {
        this.refreshSizeRestricted = true
        return currentSizes
      }
      const newSizes: AdSizes = [
        [320, 480],
        [300, 600],
        // gumgum in-article
        // eslint-disable-next-line no-magic-numbers
        [300, 400]
      ]

      if (!forceTallOnly) {
        newSizes.unshift(...currentSizes)
      }

      return newSizes
    }

    set sizes(updatedAdSizes: AdSizes) {
      super.sizes = updatedAdSizes
    }

    applyInterscrollerLayout() {
      const adReportSpace = this.rail.spaceNeededForAdReport()

      this.observeRail()
      addInterscrollerClasses(this.rail.frame, this.wrapper)
      this.rail.frame.style.height = `calc(100% - ${adReportSpace}px)`

      window.addEventListener('scroll', this.updatePosition, {
        passive: true,
        capture: false
      })
      this.updatePosition()
    }

    removeInterscrollerLayout() {
      removeInterscrollerClasses(this.rail.frame, this.wrapper)
      window.removeEventListener('scroll', this.updatePosition)
      this.wrapper.style.transform = ''
      this.rail.frame.style.height = ''
    }

    updatePosition = () => {
      // do not compute effect once sufficiently out of view
      if (!this.isInWindow) {
        return
      }

      useFixedPosition() ? this.updateCentering() : this.updateParallax()
    }

    updateParallax(): void {
      const { DIST_unavailableTopOfScreen, DIST_functionalAvailableViewport } =
        this.getScreenPositionValues()

      // amount the ad shifts during the parallax mition
      const DIST_parallaxShift = computerParallaxShiftDistance(this)

      // distance scrolling through the screen which the parallax motion occurs over
      const DIST_parallaxInEffect = computeParallaxInEffect(
        this,
        DIST_functionalAvailableViewport
      )

      // current percentage through the animation based on scrolling
      const SCALAR_percentThroughParllaxFrame = computeParallaxPercent(
        this,
        DIST_unavailableTopOfScreen,
        DIST_parallaxInEffect
      )

      // resulting shift from that distance scrolled
      const VECT_resultingParallaxShift = computeParallaxShift(
        SCALAR_percentThroughParllaxFrame,
        DIST_parallaxShift
      )

      // do the update
      updateDOMWithResults(this, VECT_resultingParallaxShift)
    }

    updateCentering(): void {
      const { DIST_unavailableTopOfScreen, DIST_functionalAvailableViewport } =
        this.getScreenPositionValues()

      const DIST_topForCentering =
        (DIST_functionalAvailableViewport - this.height) / 2
      const COORD_topForCentering =
        DIST_topForCentering + DIST_unavailableTopOfScreen

      updateDOMWithResults(this, COORD_topForCentering)
    }

    getScreenPositionValues() {
      // compute boundaries and get functional height
      const DIST_unavailableTopOfScreen = computeUnavailableTop(this)
      const DIST_unavailableBottomOfScreen = computeUnavailableBottom()
      const DIST_viewportHeight = WindowSize.height

      // effectively how much viewport is available for parallax motion to be visible
      const DIST_functionalAvailableViewport =
        DIST_viewportHeight -
        DIST_unavailableTopOfScreen -
        DIST_unavailableBottomOfScreen

      return {
        DIST_unavailableTopOfScreen,
        DIST_functionalAvailableViewport
      }
    }

    get interscrollerEnalbedForSlot(): boolean {
      return (
        this.interscrollerIsEnabled && !!this.interscrollerConfig?.isSlotValid()
      )
    }

    get interscrollerLayoutApplies(): boolean {
      return (
        this.interscrollerEnalbedForSlot &&
        this.height >= interscrollerMinHeight
      )
    }

    get interscrollerIsEnabled(): boolean {
      return interscrollerIsEnabled(this.model)
    }

    private setupObserver = doOnce((slot: any) => {
      getIntersectionObserver().onChange((elements) => {
        elements.forEach(({ target, intersectionRatio }) => {
          const slotId = target.getAttribute('data-slotid') || ''
          const targetSlot = SlotRepository.getSlotById(slotId)

          if (!targetSlot || !(slot === targetSlot)) {
            return
          }

          const railSlide = slot.rail.slide
          if (railSlide && intersectionRatio > 0) {
            slot.isInWindow = true
            railSlide.style.display = ''
          } else if (railSlide) {
            slot.isInWindow = false
            railSlide.style.display = 'none'
          }
        })
      }, interscrollerObserverConfig)
    })

    private observeRail = () => {
      this.setupObserver(this)

      getIntersectionObserver().observe(
        this.rail.frame,
        interscrollerObserverConfig
      )
    }
  }

export function interscrollerIsEnabled(
  model: Pick<
    WebModel,
    'interscroller_mobile' | 'interscroller_desktop' | 'ad_box'
  >
): boolean {
  if (forceInterscroll) {
    return true
  }

  return (
    adBoxIsEnabled(model) &&
    ((Device.isMobileOrTablet && model.interscroller_mobile) ||
      (Device.isDesktop && model.interscroller_desktop))
  )
}

function removeInterscrollerClasses(
  outerEl: HTMLElement,
  innerEl: HTMLElement
) {
  outerEl.classList.remove(interscrollerOuterClass)
  innerEl.classList.remove(interscrollerInnerClass)
}

function addInterscrollerClasses(outerEl: HTMLElement, innerEl: HTMLElement) {
  outerEl.classList.add(interscrollerOuterClass)
  innerEl.classList.add(interscrollerInnerClass)
}

function hasValidInterscrollerConfig(slot: Slot): boolean {
  return !!slot.interscrollerConfig
}

export interface IInterscrollerConfig {
  isSlotValid: () => boolean
}

/**
 * Computes the distance the ad translates through the parallax animation.
 * NOTE: this can be changed in order to modify how the parallax looks/feels.
 * @param slot
 * @returns number
 */
function computerParallaxShiftDistance(slot: Slot): number {
  return slot.height - slot.rail.frame.getBoundingClientRect().height
}

/**
 * Computes the full distance that the parallax effect transitions during.
 * NOTE: this can be change in order to modify how the parallax looks/feels.
 * @param slot
 * @param DIST_viewportHeight
 * @returns number
 */
function computeParallaxInEffect(
  slot: Slot,
  DIST_viewportHeight: number
): number {
  // shift while the adbox is all the way in screen
  return (
    DIST_viewportHeight -
    (slot.adBoxConfig?.heightPxToContain || interscrollerMinHeight)
  )
}

/**
 * Computes the percentage through the animation that has occurred based on how much
 * scrolling has happened so far
 * @param slot
 * @param DIST_unavailableTopOfScreen
 * @param DIST_parallaxInEffect
 * @returns number
 */
function computeParallaxPercent(
  slot: Slot,
  DIST_unavailableTopOfScreen: number,
  DIST_parallaxInEffect: number
) {
  const currentTop = slot.rail.frame.getBoundingClientRect().top

  const relativeTop =
    DIST_parallaxInEffect - currentTop + DIST_unavailableTopOfScreen

  const percentTroughParallax = relativeTop / DIST_parallaxInEffect

  if (percentTroughParallax <= 0) {
    return 0
  } else if (percentTroughParallax >= 1) {
    return 1
  } else {
    return percentTroughParallax
  }
}

/**
 * Returns the final adjustment (in pixels) that should be applied to make the
 * animation function
 * @param SCALAR_percentThrough
 * @param DIST_totalShift
 * @returns number
 */
function computeParallaxShift(
  SCALAR_percentThrough: number,
  DIST_totalShift: number
): number {
  // want to shift the ad UP since absolute position starts with the bottom
  // of the ad cut off by the ad box
  return SCALAR_percentThrough * DIST_totalShift * -1
}

function computeUnavailableTop(slot: Slot): number {
  const baseOffset =
    (Device.isMobileOrTablet && slot.model.mobile_header_offset) || 0

  const growCarouselActive = document.querySelector(
    'body.grow-me-scroll-carousel-active'
  )
  const growOffset = growCarouselActive ? 60 : 0
  return baseOffset + growOffset
}

function computeUnavailableBottom(): number {
  const adhesionActive = document.querySelector('body.adhesion')

  if (!adhesionActive) {
    return 0
  }

  return Device.isDesktop ? 90 : 50
}

function updateDOMWithResults(
  slot: Slot,
  VECT_resultingParallaxShift: number
): void {
  slot.wrapper.style.setProperty('top', `${VECT_resultingParallaxShift}px`)
}

function useFixedPosition() {
  return Device.isIOS || Device.browser === 'Safari'
}

const insertStyles = doOnce((styles: string) => writeCss(styles))

function getStyles(): string {
  return `
	.${interscrollerOuterClass},
	.${interscrollerOuterClass}.${railFrameSelector} {
		position: absolute;
		display: block;
		margin: 0 auto;
		inset: 0px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
		clip: rect(0px, auto, auto, 0px);
    clip-path: inset(0px 0px);
	}

	.${interscrollerInnerClass},
	.mv-ad-box .adunitwrapper.${interscrollerInnerClass}{
		position: ${useFixedPosition() ? 'fixed' : 'absolute'};
		top: 0;
	}
`
}
