import { Logger, LogLevel } from '../Logger'

const debugLogger = new Logger(LogLevel.debug)

export function overrideAncestors(
  baseElement: HTMLElement,
  styleOverrides: { [key: string]: string },
  debug: boolean
) {
  let currentElement: HTMLElement | null = baseElement.parentElement
  let changedAncestor = false
  while (currentElement) {
    for (const targetStyle in styleOverrides) {
      const currentStyleValue = getComputedStyle(currentElement)[targetStyle]
      const targetStyleValue = styleOverrides[targetStyle]
      if (currentStyleValue !== targetStyleValue) {
        if (debug) {
          debugLogger.debug(
            `MEDIAVINE OVERRIDE ANCESTOR REPORT:
                \nModifying ancestor element from '${targetStyle}: ${currentStyleValue}' to '${targetStyle}: ${targetStyleValue}'
                \nBase Element`,
            baseElement,
            '\nAncestor Modified: ',
            currentElement
          )
          changedAncestor = true
        }
        currentElement.style.setProperty(
          targetStyle,
          targetStyleValue,
          'important'
        )
      }
    }
    currentElement = currentElement.parentElement
  }

  if (debug && !changedAncestor) {
    debugLogger.debug(
      `MEDIAVINE OVERRIDE ANCESTOR REPORT: No ancestors of base element`
    )
  }
}
