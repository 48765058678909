import type { Slot } from '../slots/Slot'

export interface IIBVConfig {
  meetsIBVCriteria(): boolean
  ibvSizes(): AdSizes
}

export class IBVConfig implements IIBVConfig {
  constructor(readonly slot: Slot) {}

  meetsIBVCriteria(): boolean {
    return this.slot.meetsOutstreamCriteria()
  }

  ibvSizes() {
    return this.slot.sizes
  }
}
